import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Accordion from "../components/Accordion"
import BrandsImage from "../images/static-only/about-us/our-brands.jpg"
import AboutUsHeader from "../images/static-only/about-us/About_us_header.jpg"
import AboutUsOverview from "../images/static-only/about-us/About_us_overview.jpg"
import RandDHeader from "../images/static-only/about-us/RandD-header.jpg"

/* Accordion Listing structure = Hero > Accordions */

class AccordionListing extends React.Component {
  componentDidMount() {
    Accordion()
    document.body.classList.remove('-nav-active')
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Meet MJN - About Us</title>
        </Helmet>
        <main role="main" className="content-main -content-accordion-listing">

          <section className="hero-area -general -accordion-listing">
            <img src={AboutUsHeader} alt="A group of people gathered around a laptop" />

            <div className="hero-area__inner">
              <h1>About <strong>Us</strong></h1>
              <nav className="breadcrumb" role="navigation">
                  <Link to="/home" title="Home">
                    <i className="fas fa-arrow-left"></i>
                    Home
                  </Link>
              </nav>
            </div>
          </section>

          <section className="accordion-listing">
            <nav className="breadcrumb -accordion -white" role="navigation">
              <Link to="/home" title="Home">
                <i className="fas fa-arrow-left"></i>
                Home
              </Link>
            </nav>

            <div className="accordion">
              <div className="accordion__heading menu-button">Our Brand</div>
              <div className="accordion__content">
                <img src={AboutUsOverview} alt="Girls drinking water" />
                <div className="rich-text">
                  <p><strong>Mead Johnson Nutrition is a division of Reckitt Benckiser, a British multinational consumer goods company. As a leading manufacturer of infant formula, we are committed to developing safe, high quality, innovative products that help meet the nutritional needs of infants and children.</strong></p>
                  <p><strong>Dating back to 1895, our company was founded by Edward Mead Johnson Sr and has since become recognised as a world leader in paediatric nutrition. Over a 100 years later, our products are still trusted by parents and health care professionals.</strong></p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">R&amp;D</div>
              <div className="accordion__content">
                <img src={RandDHeader} alt="Person conducting research" />
                <div className="rich-text">
                  <p>Decades of research and study tell us children who are well-nourished from the very start are more likely to lead healthier, more productive lives – the kind of lives we all want for each and every one of our children. It is this thought that inspires us to work every day to advance the science of paediatric nutrition. Mead Johnson’s research and development team connects a virtual, global network of leading scientific experts, research initiatives and data sources. Our work allows us to pursue cutting-edge research and then efficiently and effectively transfer innovation into products containing the highest-quality ingredients. This rich heritage of innovation has earned us the trust of parents and healthcare professionals. Our research efforts include:</p>

                  <h3>Investing in Science</h3>
                  <p>We manage global research programs, from clinical studies that address safety and efficacy, to basic research on specific bioactive components in human milk.</p>
                  <h3>Commitment to Parents</h3>
                  <p>We understand the significance of the trust placed in us by parents, and we offer them ongoing reassurance about the uncompromising and rigorous quality standards to which we hold each and every facility, production line, employee and product. In addition, we help parents make sense of feeding issues and common nutrition questions to support them in providing their children the best start in life.</p>
                  <h3>Strong Partnerships</h3>
                  <p>We foster and develop strong relationships with organizations in academia, public health, paediatric medicine, dietetics and child care to collaborate on initiatives that positively impact the wellbeing of children.</p>
                  <h3>Educational Outreach</h3>
                  <p>We help bring experts from various fields together to support continuing education seminars and materials for healthcare professionals. This work gets the latest research to those caring for children around the world.</p>
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Our Brands</div>
              <div className="accordion__content">
                <div className="rich-text">
                  <p>For more than a century, Mead Johnson has led the way in developing safe, high-quality, innovative products to help meet the nutritional needs of infants and children. Click on a brand below to explore our family of products.</p>
                  
                  <img src={BrandsImage} alt="Our brand logos" />

                  <p>The information provided on this website is intended to communicate the breadth of our global product lines. We encourage all our consumers to consult their healthcare provider to discuss the nutritional choices they make for their infants and children. Regulations differ by country; therefore, not all brands are available in all countries.</p>
                </div>
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default AccordionListing